import React from 'react'
import MainLayout from '../layouts/Main'
import Button from '../Components/Button/Button'
import '../Components/Page/Abx-Competitor/styles.scss'
import LogoWallTicker from "../Components/General/LogoWallTicker/LogoWallTicker";
import DownloadForm from '../Components/Page/Resources/DownloadForm/DownloadForm'
const logos = [
        {logo: { sourceUrl: "/images/abx-competitor/ColeHaan_Wordmark_2014.svg"}},
        {logo: { sourceUrl: "/images/abx-competitor/Frame 429.svg"}},
        {logo: { sourceUrl: "/images/abx-competitor/Group.svg"}},
        {logo: { sourceUrl: "/images/abx-competitor/logo__uj.svg"}},
        {logo: { sourceUrl: "/images/abx-competitor/TJX_Logo 1.svg"}},
        {logo: { sourceUrl: "/images/abx-competitor/ColeHaan_Wordmark_2014.svg"}},
        {logo: { sourceUrl: "/images/abx-competitor/Soludos_Logo_240x@2x 2.svg"}},
]

function AbxCompetitor() {
  return (
    <MainLayout>
        <section className='abx-competitor-wrapper'>
            <div className="abx-competitor-header-container">
               <div className='abx-competitor-header-half'>
                 <h6 className='abx-competitor-top-header'>Wunderkind vs Retention.com</h6>
                 <h1>Boost email revenue by up to 10x* without sacrificing deliverability</h1>
                 <h6>Wunderkind identifies 1B profiles annually vs Retention.com’s 250M total</h6>
                 <ul>
                     <li>Identify 1 in 3 visitors to your website</li>
                     <li>Drive 6x more revenue than Retention.com, on average</li>
                 </ul>
                 <Button className="btn btn-fill">Get Started</Button>
                 <h6>*Lift in triggered email performance for a Health & Wellness brand, previously with Retention</h6>
               </div>
                <div className='abx-competitor-image-container'>
                    <img src='/images/abx-competitor/header-image-abx.svg' />
                </div>
            </div>
        </section>
        <div className="abx-competitor-logo-ticket-wrapper">
            <h2>$5 billion in revenue generated for retail and eCommerce brands in 2024</h2>
            <LogoWallTicker
            className="abx-competitor-logo-ticket-wrapper"
            logos={logos}
            />
            </div>
            <section className='abx-identification-wrapper'>
                <div className="abx-identification-container">

                <div className="flex justify-content-center align-items-center">
                    <div className="abx-identification-half-container">
                        <h2>Wunderkind has the highest identification rates on the market</h2>
                        <p>Retention's smaller identity network relies on cookies and third-party data, limiting your ability to engage potential customers.</p>
                        <p>Wunderkind recognizes up to 10X more visitors that show real intent on your site, so you can send more targeted emails and drive substantial revenue growth.</p>
                    </div>
                    <div className="abx-identification-half-image-container">
                        <img src='/images/abx-competitor/700x380_Unknown-to-known-Animation-3seconds.gif' alt='gif' />
                    </div>
                </div>
                <div className="abx-identification-image-flex-container">
                    <div className="abx-identification-image-text-container">
                        <img src='/images/abx-competitor/Group-425.svg' alt='' />
                        <h4>Identify 1 in 3 visitors</h4>
                        <p>Through our vast Identity Network of 1 billion consumer profiles.</p>
                    </div>
                    <div className="abx-identification-image-text-container">
                        <img src='/images/abx-competitor/Group-222.svg' alt='' />
                        <h4>2 Trillion events tracked per year</h4>
                        <p>Send more effective emails with deeper customer data.</p>
                    </div>
                    <div className="abx-identification-image-text-container">
                        <img src='/images/abx-competitor/Group-483.svg' alt='' />
                        <h4>Maintain email deliverability</h4>
                        <p>Keep your sender score high. Only email visitors identified by first-party data.</p>
                    </div>
                </div>
                </div>
            </section>
            <section className='abx-competitor-stats-and-card-section'>
            <div className="abx-competitor-stats-and-card-desc-container">

            <div className="abx-competitor-stats-container">
                <h3>3.1%</h3>
                <p>of total digital revenue from one-to-one email, measured last click</p>
                <div className='abx-competitor-number-border' />
                <h3>240%</h3>
                <p>lift in triggered abandonment email revenue vs. Retention.com</p>
                <div className='abx-competitor-number-border' />
                <h3>-70%</h3>
                <p>Decrease in triggered email revenue when powered by Retention.com</p>
            </div>
            <div className="abx-competitor-stat-section-desc-container">
                <div className="abx-competitor-stat-section-border" />
                <p>A luxury home & furniture brand migrated their triggered emails from Wunderkind to Retention.com to reduce costs.</p>
                <p>After experiencing a considerable drop in abandonment email revenue, the brand returned to Wunderkind to reverse the decline.</p>
                <p>Our identification advantage continues to scale email opt-ins and performance.</p>
                <div className="abx-competitor-stat-section-border" />
            </div>
            </div>
            <h2>6 reasons retailers choose Wunderkind</h2>
            <div className="abx-competitor-stat-section-card-container">
                <div className="abx-competitor-stat-section-card">
                    <h3>1. Identity Network Size</h3>
                    <img src='/images/plus.png' alt='plus' />
                    <p>Identify more visitors with purchasing intent and send emails that generate revenue</p>
                    <img src='' alt='card-background' />
                </div>
                <div className="abx-competitor-stat-section-card">
                    <h3>1. Identity Network Size</h3>
                    <img src='/images/plus.png' alt='plus' />
                    <p>Identify more visitors with purchasing intent and send emails that generate revenue</p>
                    <img src='' alt='card-background' />
                </div>
                <div className="abx-competitor-stat-section-card">
                    <h3>1. Identity Network Size</h3>
                    <img src='/images/plus.png' alt='plus' />
                    <p>Identify more visitors with purchasing intent and send emails that generate revenue</p>
                    <img src='' alt='card-background' />
                </div>
                <div className="abx-competitor-stat-section-card">
                    <h3>1. Identity Network Size</h3>
                    <img src='/images/plus.png' alt='plus' />
                    <p>Identify more visitors with purchasing intent and send emails that generate revenue</p>
                    <img src='' alt='card-background' />
                </div>
                <div className="abx-competitor-stat-section-card">
                    <h3>1. Identity Network Size</h3>
                    <img src='/images/plus.png' alt='plus' />
                    <p>Identify more visitors with purchasing intent and send emails that generate revenue</p>
                    <img src='' alt='card-background' />
                </div>
                <div className="abx-competitor-stat-section-card">
                    <h3>1. Identity Network Size</h3>
                    <img src='/images/plus.png' alt='plus' />
                    <p>Identify more visitors with purchasing intent and send emails that generate revenue</p>
                    <img src='' alt='card-background' />
                </div>
            </div>
            </section>
            <section className='abx-competitor-wknd-guarantee-section'>
                    <h2>The Wunderkind guarantee</h2>
                    <div className="abx-competitor-wknd-guarantee-image-container">
                        <img src='/images/abx-competitor/red-blue-block.svg' alt='pattern' />
                        <img src='/images/abx-competitor/black-yellow-block.svg' alt='pattern' />
                    </div>
                <p>Guaranteed boost in performance based on additional last-click revenue from defined triggered emails sent to engaged, mailable users in your CRM.</p>
                <p>Your contract includes a specific dollar amount we’re ready to guarantee through our identification and triggered email campaigns.</p>
            </section>
            <section className='abx-competitor-retention-vs-wknd-section'>
                <h6>Retention vs Wunderkind</h6>
                <h2>Wunderkind numbers speak for themselves</h2>
                <img src='/images/abx-competitor/wknd-vs-retention-table.svg' alt="" />
            </section>
            <section className='abx-competitor-retention-vs-wknd-bar-section'>
                <h2>Wunderkind outperforms Retention.com email revenue by up to 10x</h2>
                <img src='/images/abx-competitor/wknd-vs-retention-bar-graph.svg' alt=''/>
            </section>
            <section className='abx-competitor-white-glove-migration'>
                <div className="abx-competitor-white-glove-overall-container">
                    <div className="abx-competitor-white-glove-header-badge-container">
                    <h2>White glove migration from Retention</h2>
                        <p>We’ll only ask you to place a pixel on your site. The Wunderkind team takes care of strategy, copywriting, development, quality assurance testing, and campaign activation.</p>
                        <img src='/images/abx-competitor/EmailMarketing_Leader_Mid-Market_Americas_Leader 1.svg' alt='' />
                        <img src='/images/abx-competitor/EmailMarketing_Leader_Mid-Market_Leader 1.svg' alt='' />
                        <img src='/images/abx-competitor/IdentityResolution_Leader_Leader 1.svg' alt='' />
                        <img src='/images/abx-competitor/Personalization_EasiestToDoBusinessWith_EaseOfDoingBusinessWith 1.svg' alt='' />
                    </div>
                    <div className="abx-competitor-white-glove-form-container">
                        <DownloadForm formId={`2293`} />
                    </div>
                </div>
            </section>
    </MainLayout>
  )
}

export default AbxCompetitor