import { graphql } from "gatsby";
import React from "react";
import "../template-styles/performance-marketing-marketo.scss";
import DemoHeader from "../Components/Header/DemoHeader";
import DownloadForm from "../Components/Page/Resources/DownloadForm/DownloadForm.jsx";
import IndustryVideo from "../Components/General/IndustryVideo";
import Footer from "../Components/Footer/Footer.jsx";

function PerformanceMarketingMarketo({ data }) {
  const sections =
    data?.wordPress?.hPageTemplates.nodes[0].hPages.nodes[0]
      .wknd_performance_marketing_marketo_template.templateFields;

  console.log("sections data perf", sections);

  return (
    <div className="pfm-page-outer-wrapper">
      <div className="demo-wrapper">
        <DemoHeader />
      </div>
      {sections.map((section, index) => {
        const type = section?.__typename;
        switch (type) {
          case "WordPress_HPage_WkndPerformanceMarketingMarketoTemplate_TemplateFields_LandingSection":
            return (
              <div className="pfm-wrapper">
                <img
                  className="pfm-wrapper-background-pattern"
                  src="/images/our-vision/identity-image-text-pattern.svg"
                />
                <h2>{section?.mainHeader}</h2>
                <div className={"pfm-wrapper-bg-mask"}>
                  <img
                    src={
                      "/images/performance-marketing-marketo-template/background-blue-mask2.svg"
                    }
                  />
                </div>
                <div className="pfm-landing-section">
                  <div className="pfm-landing-section-header-container">
                    <h6>{section?.secondaryHeader}</h6>
                    <ul>
                      {section?.descriptionRepeatFields?.map((item, id) => {
                        return (
                          <>
                            <li key={id}>{item.description}</li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                  <DownloadForm className="download-form" formId={`2293`} />
                </div>
              </div>
            );

          case "WordPress_HPage_WkndPerformanceMarketingMarketoTemplate_TemplateFields_ImageAndDescription":
            return (
              <div className="pfm-image-and-text-wrapper">
                <div className="pfm-image-and-text-section">
                  <div className="pfm-image-and-text-image-container">
                    <img src={section?.image?.sourceUrl} alt="" />
                  </div>
                  <div className="pfm-image-and-text-quote-container">
                    <div className="pfm-image-and-text-quote-border" />
                    <div className="pfm-image-and-text-description">
                      {section?.description}
                    </div>
                    <h2>{section?.authorName}</h2>
                    <h6>{section?.authorPosition}</h6>
                    <div className="pfm-image-and-text-quote-border" />
                  </div>
                </div>
              </div>
            );
          case "WordPress_HPage_WkndPerformanceMarketingMarketoTemplate_TemplateFields_VideoSection":
            return (
              <div className="pfm-video-wrapper">
                <div className="pfm-video-container">
                  <iframe src={section?.video} />
                </div>
              </div>
            );
          case "WordPress_HPage_WkndPerformanceMarketingMarketoTemplate_TemplateFields_CardSection":
            return (
              <div className="pfm-image-and-text-wrapper">
                <div className="pfm-image-and-text-container">
                  <h2>{section?.mainHeader}</h2>
                  <div className="pfm-card-container">
                    {section?.card?.map((item, index) => {
                      return (
                        <div className="pfm-card-wrapper">
                          <div className="pfm-card">
                            {item?.numberOrIcon === "Number" ? (
                              <div className="pfm-card-number">
                                {item.number}
                              </div>
                            ) : (
                              <img src={item?.icon?.sourceUrl} alt="" />
                            )}
                            <div>{item.description}</div>
                            <div className="pfm-card-logo-container">
                              {item.cardBottomIcon?.sourceUrl && (
                                <img
                                  src={item.cardBottomIcon.sourceUrl}
                                  alt={item.industryName || "Card logo"}
                                />
                              )}
                              {/* <div>{item.industryName}</div> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );

          //

          default:
            return <></>;
        }
      })}
      <Footer />
    </div>
  );
}

export const query = graphql`
  query PerformanceMarketingMarketo($title: String!) {
    wordPress {
      hPageTemplates(
        where: { slug: "performance-marketing-marketo-template" }
      ) {
        nodes {
          hPages(where: { title: $title }) {
            nodes {
              title
              wknd_performance_marketing_marketo_template {
                templateFields {
                  ... on WordPress_HPage_WkndPerformanceMarketingMarketoTemplate_TemplateFields_LandingSection {
                    fieldGroupName
                    formId
                    mainHeader
                    secondaryHeader
                    backgroundImage {
                      sourceUrl
                    }
                    descriptionRepeatFields {
                      description
                    }
                  }
                  ... on WordPress_HPage_WkndPerformanceMarketingMarketoTemplate_TemplateFields_ImageAndDescription {
                    authorName
                    authorPosition
                    description
                    image {
                      sourceUrl
                    }
                    wrapperBackgroundColor
                  }
                  ... on WordPress_HPage_WkndPerformanceMarketingMarketoTemplate_TemplateFields_VideoSection {
                    fieldGroupName
                    video
                    wrapperBackgroundColor
                  }
                  ... on WordPress_HPage_WkndPerformanceMarketingMarketoTemplate_TemplateFields_CardSection {
                    fieldGroupName
                    mainHeader
                    wrapperBackgroundColor
                    card {
                      cardBottomIcon {
                        sourceUrl
                      }
                      description
                      icon {
                        sourceUrl
                      }
                      industryName
                      number
                      numberOrIcon
                    }
                  }
                  ... on WordPress_HPage_WkndPerformanceMarketingMarketoTemplate_TemplateFields_Divider {
                    backgroundColor
                    fieldGroupName
                    maskColor
                    orientation
                    position
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PerformanceMarketingMarketo;
